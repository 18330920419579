let width = window.innerWidth;
let height = window.innerHeight;
let size = `${width}×${height}`;
document.getElementById("size").innerHTML = size;
// console.log(`${width}×${height}`);


window.addEventListener(`resize`, event => {
    let width = window.innerWidth;
    let height = window.innerHeight;
    let size = `${width}×${height}`;
	document.getElementById("size").innerHTML = size;
}, false);